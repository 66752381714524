<template>
  <div class="detail-select-page">
    <Header  />
    <div class="form-wrap">
      <Row label="发货公司" >
        <span class="labelText" @click="getDeliveryCompanyPicker">{{deliveryCompany}}</span>
        <Popup v-model="showDeliveryCompanyPicker" position="bottom">
          <Picker
              title="选择发货公司"
              show-toolbar
              :columns="deliveryCompanyColumns"
              @confirm="onDeliveryCompanyConfirm"
              @cancel="onDeliveryCompanyCancel"
          />
        </Popup>
      </Row>
      <Row label="运输方式" >
        <span class="labelText" @click="getTransportWayPicker">{{transportWay}}</span>
        <Popup v-model="showTransportWayPicker" position="bottom">
          <Picker
              title="发货公司"
              show-toolbar
              :columns="transportWayColumns"
              @confirm="onTransportWayConfirm"
              @cancel="onTransportWayCancel"
          />
        </Popup>
      </Row>

      <Row label="产品分类" v-if="false">
        <span class="labelText" @click="getMaterialClassPicker">{{materialClass}}</span>
        <Popup v-model="showMaterialClassPicker" position="bottom">
          <Picker
              title="产品分类"
              show-toolbar
              :columns="materialClassColumns"
              @confirm="onMaterialClassConfirm"
              @cancel="onMaterialClassCancel"
          />
        </Popup>
      </Row>
      
      <Form  :rows="rows" v-model="form" />
    </div>
    <div class="two-btn-container">
      <div class="btn-wrap">
        <CustomButton size="large-plain" @click="reset">重置</CustomButton>
      </div>
      <div class="btn-wrap">
        <CustomButton size="large" @click="confirm">查询</CustomButton>
      </div>
    </div>
    <div class="detail-select-list">
      <div class="detail-select-item"  v-for="(item, index) in list" :key="index" >
        <div class="number-container" >
          编号：{{item.materialCode}}
        </div>
        <div class="detail-info-container">
          <div class="image-container">
            <img :src="item.largerUrl" class="image" />
          </div>
          <div class="detail-info-wrap" >
            <div class="name">{{item.materialName}}</div>
            <div class="unit-weight-container" >
              <div class="unit">计量单位：{{item.materialUnit}}</div>
              <div class="weight">包重：{{item.bagWeight}}</div>
            </div>
            <div class="price" >
              <div class="money">￥：{{item.price || '暂无价格'}}</div>
              <div class="get"><CustomButton class="button" size="small" @click="getPrice(index)">获取价格</CustomButton></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 无限加载 -->
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithText/index.vue'
import Form from '@/components/common/form/index.vue'
import Row from '@/components/select/row.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import InfiniteLoading from 'vue-infinite-loading'
import {fetchMaterialPrice, fetchProductList} from '@/api/select'
import { Loading,Popup,Picker } from 'vant'
import {fetchDictList} from "@/api/order";
import * as user from "@/utils/user";

export default {
  components: {
    Header,
    Form,
    Row,
    CustomButton,
    InfiniteLoading,
    Loading,
    Popup,
    Picker,
  },
  
  data() {
    return {
      value: {},
      params: {},

      deliveryCompany: '请选择',
      transportWay: '请选择',
      materialClass: '请选择',
      deliveryCompanyValue: '',
      transportWayValue: '',
      materialClassValue: '',
      deliveryCompanyColumns: [],
      transportWayColumns: [],
      materialClassColumns: [],
      showDeliveryCompanyPicker:false,
      showTransportWayPicker:false,
      showMaterialClassPicker:false,
      
      choose: [],
      rows: [
        // {label: '产品分类', key: 'classify', type: 'picker', valueString: 'dictValue', labelString: 'dictLabel'},
        // {label: '物料编码', key: 'code', type: 'input'},
        {label: '物料名称', key: 'name', type: 'input'},
        {label: '配比式', key: 'matchEq', type: 'input' ,placeholder: '举例：18-18-18'},
      ],
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {},
      isLoading: false,
    }
  },
  beforeMount() {
    this.fetchProductType()
    this.fetchCompany();
    this.fetchTransportWay();
    this.choose = this.value
  },
  methods: {

    confirm() {
      this.pageNo = 1,
      this.total = 0
      this.list = []
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]()
    },
   
    
    reset() {
      this.form = {}
      this.deliveryCompanyValue = "";
      this.deliveryCompanyValue = "";
      this.materialClassValue = "";
      this.deliveryCompany = "请选择";
      this.transportWay = "请选择";
      this.materialClass = "请选择";

      this.pageNo = 1,
      this.total = 0
      this.list = []
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]()
    },
   
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let data = {
        "dealerId": user.getDealerId(),
        "factory": this.deliveryCompanyValue || '',
        "tranMode": this.transportWayValue || '',
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "materialCode": this.form.code || '',
        "materialName": this.form.name || '',
        "materialClass": this.materialClassValue || '',
        "matchEq": this.form.matchEq || ''
      }
      let response = await fetchProductList(data)
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    async fetchProductType() {
      let response = await fetchDictList({
        "type": 'eb_material_class'
      })
      response.data.forEach(item => {
        this.materialClassColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    handleChangeLoading(loading) {
      this.isLoading = loading
    },


    async fetchCompany() {
      let response = await fetchDictList({
        "type": 'eb_factory'
      })
      response.data.forEach(item => {
        this.deliveryCompanyColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    async fetchTransportWay() {
      let response = await fetchDictList({
        "type": 'eb_tran_mode'
      })
      response.data.forEach(item => {
        this.transportWayColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },

    onDeliveryCompanyConfirm(value) {
      this.showDeliveryCompanyPicker = false;
      this.deliveryCompany = value.text;
      this.deliveryCompanyValue = value.value;
    },
    onTransportWayConfirm(value) {
      this.showTransportWayPicker = false;
      this.transportWay = value.text;
      this.transportWayValue = value.value;
    },
    onMaterialClassConfirm(value) {
      this.showMaterialClassPicker = false;
      this.materialClass = value.text;
      this.materialClassValue = value.value;
    },

    onDeliveryCompanyCancel() {
      this.showDeliveryCompanyPicker = false;
    },
    onTransportWayCancel() {
      this.showTransportWayPicker = false;
    },
    onMaterialClassCancel() {
      this.showMaterialClassPicker = false;
    },

    getDeliveryCompanyPicker() {
      this.showDeliveryCompanyPicker = true;
    },
    getTransportWayPicker() {
      this.showTransportWayPicker = true;
    },
    getMaterialClassPicker() {
      this.showMaterialClassPicker = true;
    },

    getPrice(index) {
      if ('' == this.deliveryCompanyValue || undefined == this.deliveryCompanyValue) {
        this.$toast('请选择发货公司');
        return;
      }
      if ('' == this.transportWayValue || undefined == this.transportWayValue) {
        this.$toast('请选择运输方式');
        return;
      }
      this.isLoading = true;
      fetchMaterialPrice({
        dealerId: user.getDealerId(),
        factory: this.deliveryCompanyValue,
        tranMode: this.transportWayValue,
        materialCode: this.list[index].materialCode
      }).then(res => {
        this.list[index].price = res.data.price;
        this.isLoading = false;
      }).catch(err => {
        this.$toast(err.message);
        this.isLoading = false;
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-select-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .form-wrap {
    padding-top: 12px;

    .labelText {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #818181;
    }

    
  }

  .detail-select-list {
    width: 100%;

    .detail-select-item {
      width: 100%;
      height: 152px;
      border-bottom: 1px solid  #f5dce0;
      box-sizing: border-box;
      padding: 12px 26px 9px 24px;
      background: white;
      font-size: 14px;
      text-align: left;
      &.active {
        background: #CD001F;
      }
      .number-container {
        width: 100%;
        color: #91939A;
        &.active {
          color: white;
        }
      }
      .detail-info-container {
        width: 100%;
        height: 121px;
        box-sizing: border-box;
        padding-top: 11px;
        display: flex;
        justify-content: space-between;
        .image-container {
          width: 80px;
          height: 100px;
          background: #FFFFFF;
          border: 1px solid #E4E4E4;
          opacity: 1;
          border-radius: 5px;
          .image {
            width: 100%;
            height: 100%;
          }
        }
        .detail-info-wrap {
          width: 233px;
          box-sizing: border-box;
          padding-top: 2px;
          .name {
            line-height: 20px;
            height: 40px;
            font-weight: bold;
          }
          &.active {
            color: white;
          }
          .unit-weight-container {
            width: 100%;
            color: #91939A;
            display: flex;
            margin-top: 13px;
            &.active {
              color: white;
            }
            .unit, .weight {
              display: flex;
              flex: 1;
            }
          }

          .price {
            width: 100%;
            color: #91939A;
            display: flex;
            margin-top: 5px;
            &.active {
              color: white;
            }
            .get, .money {
              display: flex;
              flex: 1;
            }
            .get {
              width: 100px;
              padding-left: 20px;
              height: 22px;
              .button {
                background-color: #FFA240;
                height: 22px;
              }
            }
            .money {
              color: red;
            }
          }
          
        }
      }
    }
  }
  
  
  .two-btn-container {
    width: 100%;
    height: 63px;
    box-sizing: border-box;
    padding: 12px 12px 13px;
    display: flex;
    justify-content: space-between;
    .btn-wrap {
      width: 170px;
    }
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>
