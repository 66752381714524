<template>
  <div class="search-condition">
    <Header type="" />

    <Row label="经销商" v-if="false">
      <span class="labelText">{{nick}}</span>
    </Row>
    <Row label="发货公司" >
      <span class="labelText" @click="getDeliveryCompanyPicker">{{deliveryCompany}}</span>
      <Popup v-model="showDeliveryCompanyPicker" position="bottom">
        <Picker
            title="选择发货公司"
            show-toolbar
            :columns="deliveryCompanyColumns"
            @confirm="onDeliveryCompanyConfirm"
            @cancel="onDeliveryCompanyCancel"
        />
      </Popup>
    </Row>
    <Row label="运输方式" >
      <span class="labelText" @click="getTransportWayPicker">{{transportWay}}</span>
      <Popup v-model="showTransportWayPicker" position="bottom">
        <Picker
            title="发货公司"
            show-toolbar
            :columns="transportWayColumns"
            @confirm="onTransportWayConfirm"
            @cancel="onTransportWayCancel"
        />
      </Popup>
    </Row>

    <div class="content" v-for="item in product" :key="item.materialCode">
      <div class="productCode">
        <div>物料编码：{{item.materialCode}}</div>
        <div>价格：<span class="price">{{item.price}}</span></div>
      </div>
      <div class="productName">
        <div class="name">{{item.materialName}}</div>
      </div>
    </div>
    


    <div class="button-container">
      <Button class="button cancel" size="small-plain" @click="cancel">取消</Button>
      <Button class="button select" size="small" @click="selectProduct">选择物料</Button>
    </div>
      
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>

    <ProductDetailSelect
        v-model="product"
        v-if="showProductDetail"
        @confirm="handleAddProduct"
        :params="productParams"
    />
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import * as user from "@/utils/user";
import Row from '@/components/select/row.vue'
import Button from '../../../components/common/button/CustomButton';
import { Loading,Picker,Popup } from 'vant'
import {fetchDictList} from "@/api/order";
import ProductDetailSelect from "@/views/select/orderProductDetailSelect";

export default {
  components: {
    Header,
    Row,
    Button,
    Loading,
    ProductDetailSelect,
    Picker,
    Popup
  },
  data() {
    return {
      isLoading: false,
      params: {},
      nick: user.getNickName(),
      deliveryCompany: '请选择',
      transportWay: '请选择',
      deliveryCompanyValue: '',
      transportWayValue: '',
      deliveryCompanyColumns: [],
      transportWayColumns: [],
      showDeliveryCompanyPicker:false,
      showTransportWayPicker:false,
      product: [], // 产品列表
      showProductDetail:false,
      productParams:{},
    }
  },
  beforeMount() {
    try {
      this.fetchCompany();
      this.fetchTransportWay();
    }
    catch(err) {
      this.$toast('网络问题')
    }
  },
 
  async activated() {
    this.deliveryCompany = this.$route.params.deliveryCompany || '请选择',
    this.transportWay = this.$route.params.transportWay || '请选择',
    this.deliveryCompanyValue = this.$route.params.deliveryCompanyValue || '',
    this.transportWayValue = this.$route.params.transportWayValue || ''
  },
 
  methods: {
    async fetchCompany() {
      let response = await fetchDictList({
        "type": 'eb_factory'
      })
      response.data.forEach(item => {
        this.deliveryCompanyColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    async fetchTransportWay() {
      let response = await fetchDictList({
        "type": 'eb_tran_mode'
      })
      response.data.forEach(item => {
        this.transportWayColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },

    

    onDeliveryCompanyConfirm(value) {
      this.showDeliveryCompanyPicker = false;
      this.deliveryCompany = value.text;
      this.deliveryCompanyValue = value.value;
    },
    onTransportWayConfirm(value) {
      this.showTransportWayPicker = false;
      this.transportWay = value.text;
      this.transportWayValue = value.value;
    },

    onDeliveryCompanyCancel() {
      this.showDeliveryCompanyPicker = false;
    },
    onTransportWayCancel() {
      this.showTransportWayPicker = false;
    },

    getDeliveryCompanyPicker() {
      this.showDeliveryCompanyPicker = true;
    },
    getTransportWayPicker() {
      this.showTransportWayPicker = true;
    },
    
    cancel() {
      this.product = [];
      this.$router.go(-1);
    },
    
    selectProduct() {
      if ('' == this.deliveryCompanyValue || undefined == this.deliveryCompanyValue) {
        this.$toast('请选择发货公司');
        return;
      }
      if ('' == this.transportWayValue || undefined == this.transportWayValue) {
        this.$toast('请选择运输方式');
        return;
      }
      this.product = [];
      this.productParams = {
        "dealerId": user.getDealerId(),
        "factory": this.deliveryCompanyValue || '10',
        "tranMode": this.transportWayValue || '10'
      }
      this.showProductDetail = true;
    },
    handleAddProduct() {
      this.showProductDetail = false;
    },


  },
}
</script>

<style lang="scss" scoped>
.search-condition {
  background: #fafbfc;
  min-height: 100vh;
  
  .labelText {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #818181;
  }

  .content {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    margin-top: 10px;
    padding: 8px 20px 5px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #818181;
    
    .productCode {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        color: red;
      }
    }

    .productName {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }
    
  }
  
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }

  .button {
    width: 157px;
    &-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position:fixed;
      bottom: 20px;
    }
  }
  .cancel {
    margin-left: 15px;
  }
  .select {
    margin-right: 15px;
  }


  .product-list {
    border-bottom: 1px solid #fafbfc;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 24px 15px;
    font-size: 14px;
    .label-value-wrap {
      width: 100%;
      min-height: 20px;
      display: flex;
      align-items: center;
      text-align: left;
      .label {
        white-space: nowrap;
      }
      .value {
        margin-left: 9px;
      }
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
  .active {
    color: #FFFFFF;
    background: #CD001F;
  }
  .not-active {
    background: white;
    .label {
      color: #818181;
    }
    .value {
      color: #2C2C2C;
    }
  }
  
}
</style>  